const CategoriesData = [
  {
    category: "Politics",
    catValue: "Politics"

  },
  {
    category: "Business",
    catValue: "Business",

  },
  {
    category: "Sports",
    catValue: "Sports"

  },
  {
    category: "Science&Tech",
    catValue: "Science&Tech"

  },
  {
    category: "Other",
    catValue: "Other"

  },
  {
    category: "International",
    catValue: "International"

  },
  {
    category: "Entertainment",
    catValue: "Entertainment"
  },
]
export default CategoriesData;